import FingerprintJS from '@fingerprintjs/fingerprintjs';
import omitBy from 'lodash/omitBy';
import moment from 'moment';
import QueryString from 'qs';

import { HHMM, YMDH } from '@/utils/constants';

import { parseValue } from './parser';

export interface TFormatTime {
  start_time: string;
  start_date: string;
  end_time: string;
  end_date: string;
}

const initScript = (id: string, src: string) => {
  const script = document.createElement('script');
  script.id = id;
  script.src = src;
  script.async = true;
  document.head.append(script);
};

const omitEmpty = <T extends object>(o: T): Partial<T> =>
  Object.entries(o).reduce(
    (acc: Partial<typeof o>, [key, value]) =>
      value instanceof Object && !Array.isArray(value)
        ? { ...acc, [key]: omitEmpty(value) }
        : { ...acc, ...omitBy({ [key]: value }, v => v === null || v === '') },
    {}
  );

const flatten = <T extends Record<string, unknown>>(obj: T, parent?: string) => {
  let res: Record<string, unknown> = {};

  for (const [key, value] of Object.entries(obj)) {
    const propName = parent ? parent + '_' + key : key;
    const flattened: Record<string, unknown> = {};

    if (value instanceof Date) {
      flattened[key] = value.toISOString();
    } else if (typeof value === 'object' && value !== null) {
      res = { ...res, ...flatten(value as T, propName) };
    } else {
      res[propName] = value;
    }
  }

  return res;
};

const generateVisitorId = async () => {
  const fp = await FingerprintJS.load();
  const { visitorId } = await fp.get();
  return visitorId;
};

const scroll = (selector: string, options: ScrollToOptions) => {
  const element = document.querySelector(selector);
  if (!element) return;
  element.scrollTop += options.top ?? 0;
};

const signInWithGoogle = () => (window.location.href = '/backend/api/auth/redirect-google');

const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

const grayscaleColor = (r: number, g: number, b: number) => r * 0.299 + g * 0.587 + b * 0.114;

const setScrollToAnchorLinks = () => {
  const links = document.querySelectorAll('a[href^="#"]');

  links.forEach(link => {
    link.addEventListener('click', event => {
      event.preventDefault();

      const elementID = document.querySelector(link.getAttribute('href') ?? '#');

      elementID?.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    });
  });
};

const parseHashString = (hash: string): Dictionary<any> => {
  return QueryString.parse(hash, {
    decoder: parseValue,
    arrayLimit: Infinity,
  });
};

const parseJson = (input: string) => {
  const correctedJson = input.replace(/([{,]\s*)([A-Za-z0-9_]+)\s*:/g, '$1"$2":');
  return JSON.parse(correctedJson);
};

export const formatTimeToUTC = <T extends TFormatTime>(params: T): T => {
  const defaultDate = '1970-01-01';
  const convertTimeToUTC = (date?: string, time?: string) => {
    if (time) {
      const dateTime = moment(`${date || defaultDate} ${time}`, `${YMDH} ${HHMM}`);
      const utcTime = dateTime.utc().format(HHMM);
      const utcDate = dateTime.utc().format(YMDH);

      return { utcTime, utcDate };
    }
    return { utcTime: '', utcDate: date || '' };
  };

  const startResult = convertTimeToUTC(params.start_date, params.start_time);
  const endResult = convertTimeToUTC(params.end_date, params.end_time);

  return {
    ...params,
    start_date: startResult.utcDate !== defaultDate ? startResult.utcDate : '',
    start_time: startResult.utcTime,
    end_date: endResult.utcDate !== defaultDate ? endResult.utcDate : '',
    end_time: endResult.utcTime,
  };
};

export const formatTimeFromUTC = <T extends TFormatTime>(params: T): T => {
  const defaultDate = '1970-01-01';
  const convertFromUTC = (date?: string, time?: string) => {
    if (time) {
      const dateTime = moment.utc(`${date || defaultDate} ${time}`, `${YMDH} ${HHMM}`);
      const localTime = dateTime.local().format(HHMM);
      const localDate = dateTime.local().format(YMDH);

      return { localTime, localDate };
    }
    return { localTime: '', localDate: date || '' };
  };

  const startResult = convertFromUTC(params.start_date, params.start_time);
  const endResult = convertFromUTC(params.end_date, params.end_time);

  return {
    ...params,
    start_date: startResult.localDate !== defaultDate ? startResult.localDate : '',
    start_time: startResult.localTime,
    end_date: endResult.localDate !== defaultDate ? endResult.localDate : '',
    end_time: endResult.localTime,
  };
};

const RETRY_DELAY = 5000;

const retriesHandler = async <T>(
  callback: () => Promise<T>,
  maxAttempts: number,
  statusCodes: number[],
  errorCallback?: () => void
): Promise<T> => {
  const execute = async (attempt: number): Promise<T> => {
    try {
      const data = await callback();
      return data;
    } catch (err) {
      if (errorCallback && attempt === maxAttempts + 1) errorCallback();
      if (attempt > maxAttempts || !statusCodes.includes((err as any).response?.status)) throw err;
      const nextAttempt = attempt + 1;
      console.error(`Retrying after ${RETRY_DELAY / 1000} seconds due to:`, err);
      return delay(() => execute(nextAttempt), RETRY_DELAY);
    }
  };
  return execute(1);
};

const delay = <T>(callback: () => Promise<T>, ms: number): Promise<T> =>
  new Promise(resolve => setTimeout(() => resolve(callback()), ms));

export {
  flatten,
  generateVisitorId,
  grayscaleColor,
  hexToRgb,
  initScript,
  omitEmpty,
  parseHashString,
  parseJson,
  retriesHandler,
  scroll,
  setScrollToAnchorLinks,
  signInWithGoogle,
};
