import capitalize from 'lodash/capitalize';
import omit from 'lodash/omit';
import { ActionTree, GetterTree, MutationTree } from 'vuex';

import {
  ApplicationState,
  ErrorIdentifier,
  IBroadcastEvent,
  INotification,
  NotificationsQuery,
  RootState,
} from '@/interfaces';
import AdminService from '@/services/AdminService';
import NotificationService from '@/services/NotificationService';
import { MenuService } from '@/services/StorageService';
import { ThemeService } from '@/services/StorageService';
import {
  FETCH_MAINTENANCE,
  FETCH_META_DATA,
  FETCH_NOTIFICATIONS,
  FETCH_ROLES,
  FETCH_TECHNICAL_ALERTS,
  GET_NOTIFICATIONS_COUNT,
  UPDATE_NOTIFICATIONS,
} from '@/store/types/actions';
import {
  SET_BROADCAST_EVENTS,
  SET_ERROR,
  SET_MAINTENANCE,
  SET_MENU_NOTIFICATIONS,
  SET_META_DATA,
  SET_NOTIFICATIONS_COUNT,
  SET_PROFILE_NOTIFICATIONS,
  SET_ROLES,
  SET_TECHNICAL_ALERTS,
  TOGGLE_MENU,
  TOGGLE_THEME,
} from '@/store/types/mutations';
import { replaceSnakeCaseToSpace } from '@/utils/filters';
import { formatTimeFromUTC } from '@/utils/helpers';

const state: ApplicationState = {
  error: null,
  rolesList: { loading: false, error: null, data: [] },
  menuOpened: MenuService.getExpand(),
  isDarkTheme: ThemeService.getDarkTheme() ?? false,
  environment: {
    development: process.env.NODE_ENV === 'development',
    stage: process.env.VUE_APP_IS_STAGE === 'true',
    production: process.env.NODE_ENV === 'production',
  },
  maintenance: false,
  technicalAlerts: [],
  metaData: {
    loading: false,
    error: null,
    data: {
      created_requests: null,
    },
  },
  profileNotifications: {
    loading: false,
    error: null,
    data: {
      data: [],
      links: {
        first: null,
        last: null,
        next: null,
        prev: null,
      },
      meta: {
        current_page: 1,
        per_page: 100,
        last_page: 2,
        from: 1,
        to: 2,
        total: 100,
      },
    },
  },
  menuNotifications: {
    loading: false,
    error: null,
    data: {
      data: [],
      links: {
        first: null,
        last: null,
        next: null,
        prev: null,
      },
      meta: {
        current_page: 1,
        per_page: 100,
        last_page: 2,
        from: 1,
        to: 2,
        total: 100,
      },
    },
  },
  broadcastEvents: [],
  notificationsCount: { loading: false, error: null, data: [] },
};

const getters: GetterTree<ApplicationState, RootState> = {
  error: state => state.error,
  rolesList: state => state.rolesList,
  menuOpened: state => state.menuOpened,
  maintenance: state => state.maintenance,
  technicalAlerts: state => state.technicalAlerts,
  isDarkTheme: state => state.isDarkTheme,
  environment: state =>
    (Object.keys(state.environment) as Environment[]).find(key => state.environment[key]) ??
    process.env.NODE_ENV,
  metaData: state => state.metaData,
  profileNotifications: state => state.profileNotifications,
  menuNotifications: state => state.menuNotifications,
  broadcastEvents: state => state.broadcastEvents,
  notificationsCount: state => state.notificationsCount,
};

const mutations: MutationTree<ApplicationState> = {
  [SET_ERROR](state, error: ErrorIdentifier) {
    state.error = error;
  },
  [TOGGLE_MENU](state) {
    state.menuOpened = !state.menuOpened;
  },
  [TOGGLE_THEME](state) {
    state.isDarkTheme = !state.isDarkTheme;
  },
  [SET_META_DATA](state, payload: ApplicationState['metaData']) {
    state.metaData = payload;
  },
  [SET_MAINTENANCE](state, payload: ApplicationState['maintenance']) {
    state.maintenance = payload;
  },
  [SET_TECHNICAL_ALERTS](state, payload: ApplicationState['technicalAlerts']) {
    state.technicalAlerts = payload;
  },
  [SET_ROLES](state, payload: ApplicationState['rolesList']) {
    state.rolesList = payload;
  },
  [SET_PROFILE_NOTIFICATIONS](state, payload: ApplicationState['profileNotifications']) {
    state.profileNotifications = payload;
  },
  [SET_MENU_NOTIFICATIONS](state, payload: ApplicationState['menuNotifications']) {
    state.menuNotifications = payload;
  },
  [SET_NOTIFICATIONS_COUNT](state, payload: ApplicationState['notificationsCount']) {
    state.notificationsCount = payload;
  },
  [SET_BROADCAST_EVENTS](state, broadcastEvents: IBroadcastEvent[]) {
    state.broadcastEvents = broadcastEvents;
  },
};

const actions: ActionTree<ApplicationState, RootState> = {
  async [FETCH_META_DATA]({ commit }) {
    try {
      commit(SET_META_DATA, { ...state.metaData, loading: true, error: null });
      const { data } = await AdminService.getMetaData();
      commit(SET_META_DATA, { ...state.metaData, data, loading: false });
    } catch (error) {
      commit(SET_META_DATA, { ...state.metaData, loading: false, error });
    }
  },

  async [FETCH_ROLES]({ state, commit }) {
    const colors = [
      'blue',
      'deep-purple',
      'green',
      'none',
      'teal',
      'none',
      'deep-purple lighten-1',
      'pink lighten-2',
      'orange',
      'purple lighten-2',
      'indigo lighten-2',
      'light-blue lighten-1',
      'light-green darken-1',
      'lime lighten-1',
      'amber lighten-1',
    ];
    try {
      commit(SET_ROLES, { ...state.rolesList, loading: true, error: null });
      const response = await AdminService.getRoles();
      const data = Object.entries(response.data)
        .map(([name, id]) => ({
          id,
          name,
          text: capitalize(replaceSnakeCaseToSpace(name)),
          color: colors[(id % colors.length) - 1],
          privileged: name !== 'publisher',
        }))
        .sort((a, b) => (a.text > b.text ? 1 : -1));
      commit(SET_ROLES, { ...state.rolesList, loading: false, data });
    } catch (error) {
      commit(SET_ROLES, { ...state.rolesList, loading: false, error });
    }
  },

  async [FETCH_META_DATA]({ commit }) {
    const { data } = await AdminService.getMetaData();
    commit(SET_META_DATA, data);
  },

  async [FETCH_MAINTENANCE]({ commit }) {
    try {
      const { data } = await AdminService.getMaintenance();
      commit(SET_MAINTENANCE, data.maintenance);
    } catch (e) {
      commit(SET_MAINTENANCE, false);
    }
  },

  async [FETCH_TECHNICAL_ALERTS]({ commit }) {
    try {
      const { data } = await AdminService.getTechnicalAlerts();
      const alerts = data.map(alert => formatTimeFromUTC(alert));
      commit(SET_TECHNICAL_ALERTS, alerts);
    } catch (e) {
      commit(SET_TECHNICAL_ALERTS, []);
    }
  },

  async [FETCH_NOTIFICATIONS](
    { commit },
    params: NotificationsQuery & { section: 'menu' | 'profile' }
  ) {
    try {
      commit(params.section === 'menu' ? SET_MENU_NOTIFICATIONS : SET_PROFILE_NOTIFICATIONS, {
        ...(params.section === 'menu' ? state.menuNotifications : state.profileNotifications),
        loading: true,
        error: null,
      });
      const response = await NotificationService.getNotifications(omit(params, 'section'));
      const data = {
        ...(params.section === 'menu'
          ? state.menuNotifications.data
          : state.profileNotifications.data),
        ...response,
        data:
          +response?.meta?.current_page > 1 && params.limit !== 0
            ? [
                ...(params.section === 'menu'
                  ? state.menuNotifications.data
                  : state.profileNotifications.data
                ).data,
                ...response.data,
              ]
            : response.data,
      };
      commit(params.section === 'menu' ? SET_MENU_NOTIFICATIONS : SET_PROFILE_NOTIFICATIONS, {
        ...(params.section === 'menu' ? state.menuNotifications : state.profileNotifications),
        loading: false,
        data,
      });
    } catch (error) {
      commit(params.section === 'menu' ? SET_MENU_NOTIFICATIONS : SET_PROFILE_NOTIFICATIONS, {
        ...(params.section === 'menu' ? state.menuNotifications : state.profileNotifications),
        loading: false,
        error,
      });
    }
  },

  async [GET_NOTIFICATIONS_COUNT]({ commit }) {
    try {
      commit(SET_NOTIFICATIONS_COUNT, { ...state.notificationsCount, loading: true, error: null });
      const { data } = await NotificationService.getNotificationsCount();
      commit(SET_NOTIFICATIONS_COUNT, { ...state.notificationsCount, loading: false, data });
    } catch (error) {
      commit(SET_NOTIFICATIONS_COUNT, { ...state.notificationsCount, loading: false, error });
    }
  },

  async [UPDATE_NOTIFICATIONS](
    { state, commit },
    payload: {
      ids: number[];
      status: INotification['status'];
    }
  ) {
    try {
      commit(SET_PROFILE_NOTIFICATIONS, {
        ...state.profileNotifications,
        loading: true,
        error: null,
      });
      commit(SET_MENU_NOTIFICATIONS, {
        ...state.menuNotifications,
        loading: true,
        error: null,
      });
      await NotificationService.updateNotifications(payload);
      commit(SET_PROFILE_NOTIFICATIONS, {
        ...state.profileNotifications,
        loading: false,
        data: {
          ...state.profileNotifications.data,
          data: state.profileNotifications.data.data.map((notification: INotification) => ({
            ...notification,
            ...(payload.ids.map(Number).includes(+notification.id) || !payload.ids.length
              ? { status: payload.status }
              : {}),
          })),
        },
      });
      commit(SET_MENU_NOTIFICATIONS, {
        ...state.menuNotifications,
        loading: false,
        data: {
          ...state.menuNotifications.data,
          data: state.menuNotifications.data.data.map((notification: INotification) => ({
            ...notification,
            ...(payload.ids.map(Number).includes(+notification.id) || !payload.ids.length
              ? { status: payload.status }
              : {}),
          })),
        },
      });
    } catch (error) {
      commit(SET_PROFILE_NOTIFICATIONS, { ...state.profileNotifications, loading: false, error });
      commit(SET_MENU_NOTIFICATIONS, { ...state.menuNotifications, loading: false, error });
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
