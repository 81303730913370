












import { Component, Vue } from 'vue-property-decorator';

type TMainColors = {
  Clickadilla: string;
  MyBid: string;
  Onclicka: string;
  DEFAULT: string;
};

@Component
export default class Question extends Vue {
  mainColors: TMainColors = {
    Clickadilla: '#02C39A',
    MyBid: '#FF58F8',
    Onclicka: '#34343E',
    DEFAULT: '#1E90FF',
  };
  backgroundColors: Partial<TMainColors> = {
    Onclicka: '#FFB525',
    Clickadilla: 'trasparent',
    DEFAULT: 'white',
  };

  get isClickadilla() {
    return process.env.VUE_APP_ORG_NAME === 'Clickadilla';
  }

  get fill() {
    const appName: keyof TMainColors = process.env.VUE_APP_ORG_NAME;

    return {
      path: this.mainColors[appName] || this.mainColors.DEFAULT,
      background: this.backgroundColors[appName] || this.backgroundColors.DEFAULT,
    };
  }
}
