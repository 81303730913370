import { AxiosError } from 'axios';
import { ActionTree, GetterTree, MutationTree } from 'vuex';

import { IProfile, IUserExtended, RootState, UserState } from '@/interfaces';
import UserService from '@/services/UserService';
import TagPermissionsModel from '@/store/models/TagPermissionsModel';
import UserModel from '@/store/models/UserModel';
import {
  FETCH_PROFILE,
  INIT_USER,
  REVOKE_GOOGLE_PROPERTY,
  UPDATE_PROFILE,
} from '@/store/types/actions';
import { SET_PROFILE, SET_TAG_PERMISSIONS, SET_USER } from '@/store/types/mutations';

import { can } from '../helpers';

const state: UserState = {
  user: {
    loading: false,
    errors: {},
    data: ({ ...UserModel } as any) as IUserExtended,
  },
  profile: {
    loading: false,
    errors: {},
    data: ({ ...UserModel } as any) as IProfile,
  },
  tagPermissions: {
    loading: false,
    errors: {},
    data: {
      ...TagPermissionsModel,
    },
  },
};

const getters: GetterTree<UserState, RootState> = {
  user: state => state.user,
  roles: state => state.user?.data?.roles || [],
  testGroup: state => state.user.data.test_group_name,
  ownManager: state => state.user.data.manager,
  ownAccountManager: state => state.user.data.account_manager,
  permissions: state => state.user.data.permissions,
  tagPermissions: state => state.tagPermissions,
  fullUserName: state =>
    `${state.user.data.first_name || ''} ${state.user.data.last_name || ''}`.trim(),
  isEmailValid: state => !state.user.data.wrong_email_address,
  email: state => state.user.data.email,
  emailVerified: state => state.user.data.email_verified_at !== null,
  hasPendingEmailChange: state => state.user.data.has_pending_email_change,
  yandexMetrikaTokenStatus: state => state.user.data.yandex_metrika_token_status,
  googleAnalyticsTokenStatus: state => state.user.data.google_analytics_refresh_token_status,
  profile: state => state.profile,
};

const mutations: MutationTree<UserState> = {
  [SET_USER](state, payload: UserState['user']) {
    state.user = payload;
  },
  [SET_PROFILE](state, payload: UserState['profile']) {
    state.profile = payload;
  },
  [SET_TAG_PERMISSIONS](state, payload: UserState['tagPermissions']) {
    state.tagPermissions = payload;
  },
};

const actions: ActionTree<UserState, RootState> = {
  async [INIT_USER]({ state, commit }) {
    try {
      commit(SET_USER, { ...state.user, loading: true, errors: {} });
      const response = await UserService.getAuthenticatedUser();
      commit(SET_USER, { ...state.user, data: response.data, loading: false });
      commit(SET_TAG_PERMISSIONS, {
        ...state.tagPermissions,
        data: {
          spot: {
            delete: true,
            restore: false,
            updateAny: true,
            seeOptimization: false,
            seeMoneyRedirection: false,
            updateHidden: true,
          },
          admin_spot_options: {
            updateRedirectChrome: can('updateRedirectChrome', 'admin_spots'),
            updateABTestingInpagePush: can('updateABTestingInpagePush', 'admin_spots'),
            updateAppearanceEventInpagePush: can('updateAppearanceEventInpagePush', 'admin_spots'),
            updateBetterAdsInpagePush: can('updateBetterAdsInpagePush', 'admin_spots'),
            updateBetterAdsMobileInpagePush: can('updateBetterAdsMobileInpagePush', 'admin_spots'),
            updateBrandingInpagePush: can('updateBrandingInpagePush', 'admin_spots'),
            updateDefZoneIdInpagePush: can('updateDefZoneIdInpagePush', 'admin_spots'),
            updateIsPositionLockedInpagePush: can(
              'updateIsPositionLockedInpagePush',
              'admin_spots'
            ),
            updateIsNotifyAnimationInpagePush: can(
              'updateIsNotifyAnimationInpagePush',
              'admin_spots'
            ),
            updateLargeCrossButtonInpagePush: can(
              'updateLargeCrossButtonInpagePush',
              'admin_spots'
            ),
            updateMlCloseRatioInpagePush: can('updateMlCloseRatioInpagePush', 'admin_spots'),
            updateMlCloseRatioModalInpagePush: can(
              'updateMlCloseRatioModalInpagePush',
              'admin_spots'
            ),
            updateParamsToExtendInpagePush: can('updateParamsToExtendInpagePush', 'admin_spots'),
            updatePlacementsWithOutsideCloseInpagePush: can(
              'updatePlacementsWithOutsideCloseInpagePush',
              'admin_spots'
            ),
            updateProxyDomainInpagePush: can('updateProxyDomainInpagePush', 'admin_spots'),
            updateStartOnFullscreenEndInpagePush: can(
              'updateStartOnFullscreenEndInpagePush',
              'admin_spots'
            ),
            updateSuggestiveInpagePush: can('updateSuggestiveInpagePush', 'admin_spots'),
            updateTabunderInpagePush: can('updateTabunderInpagePush', 'admin_spots'),
            updateTargetingInpagePush: can('updateTargetingInpagePush', 'admin_spots'),
            updateServiceWorkerPathPush: can('updateServiceWorkerPathPush', 'admin_spots'),
            updateTargetingPush: can('updateTargetingPush', 'admin_spots'),
            updateTargetingCalendar: can('updateTargetingCalendar', 'admin_spots'),
            updateExoclickZoneIdPopunder: can('updateExoclickZoneIdPopunder', 'admin_spots'),
            updateFriendlyGooglePopunder: can('updateFriendlyGooglePopunder', 'admin_spots'),
            updateFriendlyYandexPopunder: can('updateFriendlyYandexPopunder', 'admin_spots'),
            updateFriendlyYandexInterstitial: can(
              'updateFriendlyYandexInterstitial',
              'admin_spots'
            ),
            updatePixelModePopunder: can('updatePixelModePopunder', 'admin_spots'),
            updateBackButtonDirectPopunder: can('updateBackButtonDirectPopunder', 'admin_spots'),
            updateDelayPerFirstPopPopunder: can('updateDelayPerFirstPopPopunder', 'admin_spots'),
            updateSspIdPopunder: can('updateSspIdPopunder', 'admin_spots'),
            updateSmartCappingPopunder: can('updateSmartCappingPopunder', 'admin_spots'),
            updateOverlayPopunder: can('updateOverlayPopunder', 'admin_spots'),
            updateTargetingPopunder: can('updateTargetingPopunder', 'admin_spots'),
            updateCloseRatioInStreamAd: can('updateCloseRatioInStreamAd', 'admin_spots'),
            updateAutoRepeatInStreamAd: can('updateAutoRepeatInStreamAd', 'spots'),
            updateExoclickZoneIdInStreamAd: can('updateExoclickZoneIdInStreamAd', 'admin_spots'),
            updateRefreshDelayInStreamAd: can('updateRefreshDelayInStreamAd', 'admin_spots'),
            updateRefreshQuantityInStreamAd: can('updateRefreshQuantityInStreamAd', 'admin_spots'),
            updateSequenceInStreamAd: can('updateSequenceInStreamAd', 'admin_spots'),
            updateSspIdInStreamAd: can('updateSspIdInStreamAd', 'admin_spots'),
            updateTargetingInStreamAd: can('updateTargetingInStreamAd', 'admin_spots'),
            updateMagmaBrandingInStreamAd: can('updateMagmaBrandingInStreamAd', 'admin_spots'),
            updateMagmaBrandingTelegramAppVideo: can(
              'updateMagmaBrandingTelegramAppVideo',
              'admin_spots'
            ),
            updateCloseRatioTelegramAppVideo: can(
              'updateCloseRatioTelegramAppVideo',
              'admin_spots'
            ),
            updateSspIdTelegramAppVideo: can('updateSspIdTelegramAppVideo', 'admin_spots'),
            updateRefreshDelayTelegramAppVideo: can(
              'updateRefreshDelayTelegramAppVideo',
              'admin_spots'
            ),
            updateRefreshQuantityTelegramAppVideo: can(
              'updateRefreshQuantityTelegramAppVideo',
              'admin_spots'
            ),
            updateSequenceTelegramAppVideo: can('updateSequenceTelegramAppVideo', 'admin_spots'),
            updateExoclickZoneIdTelegramAppVideo: can(
              'updateExoclickZoneIdTelegramAppVideo',
              'admin_spots'
            ),
            updateIsUrlHashingTelegramAppVideo: can(
              'updateIsUrlHashingTelegramAppVideo',
              'admin_spots'
            ),
            updateDisableCtaTelegramAppVideo: can(
              'updateDisableCtaTelegramAppVideo',
              'admin_spots'
            ),
            updateCustomSettingsTelegramAppVideo: can(
              'updateCustomSettingsTelegramAppVideo',
              'admin_spots'
            ),
            updateBackUrlInStreamAd: can('updateBackUrlInStreamAd', 'spots'),
            updateMinCpmInStreamAd: can('updateMinCpmInStreamAd', 'spots'),
            updateAntiblockRefreshVisibilityAreaBanner: can(
              'updateAntiblockRefreshVisibilityAreaBanner',
              'admin_spots'
            ),
            updateExoclickZoneIdBanner: can('updateExoclickZoneIdBanner', 'admin_spots'),
            updateHasSplitTestsBanner: can('updateHasSplitTestsBanner', 'admin_spots'),
            updateIsAntiblockRefreshBanner: can('updateIsAntiblockRefreshBanner', 'admin_spots'),
            updateMultipleBanner: can('updateMultipleBanner', 'admin_spots'),
            updatePlacementTypeIdBanner: can('updatePlacementTypeIdBanner', 'admin_spots'),
            updateRefreshFrequencyBanner: can('updateRefreshFrequencyBanner', 'admin_spots'),
            updateRefreshTypeBanner: can('updateRefreshTypeBanner', 'admin_spots'),
            updateReplacementSpotIdBanner: can('updateReplacementSpotIdBanner', 'admin_spots'),
            updateSmartRefreshBanner: can('updateSmartRefreshBanner', 'admin_spots'),
            updateSspIdBanner: can('updateSspIdBanner', 'admin_spots'),
            updateTargetingBanner: can('updateTargetingBanner', 'admin_spots'),
            updateRefreshNative: can('updateRefreshNative', 'admin_spots'),
            updateBetterAdsNative: can('updateBetterAdsNative', 'admin_spots'),
            updateTargetingNative: can('updateTargetingNative', 'admin_spots'),
            updateTargetingInterstitial: can('updateTargetingInterstitial', 'admin_spots'),
            updateSspIdInterstitial: can('updateSspIdInterstitial', 'admin_spots'),
            updateZeroSkipTimeInterstitial: can('updateZeroSkipTimeInterstitial', 'admin_spots'),
            updateTargetingDirectLink: can('updateTargetingDirectLink', 'admin_spots'),
            banSpot: can('ban', 'admin_spots'),
            updateIsUrlHashingNative: can('updateIsUrlHashingNative', 'admin_spots'),
            updateIsUrlHashingPopunder: can('updateIsUrlHashingPopunder', 'admin_spots'),
            updateIsUrlHashingInStreamAd: can('updateIsUrlHashingInStreamAd', 'admin_spots'),
            updateDisableCtaInStreamAd: can('updateDisableCtaInStreamAd', 'admin_spots'),
            updateIsUrlHashingBanner: can('updateIsUrlHashingBanner', 'admin_spots'),
            updateIsUrlHashingInterstitial: can('updateIsUrlHashingInterstitial', 'admin_spots'),
            updateIsUrlHashingInpagePush: can('updateIsUrlHashingInpagePush', 'admin_spots'),
            updateIsUrlHashingPush: can('updateIsUrlHashingPush', 'admin_spots'),
            updateIsUrlHashingCalendar: can('updateIsUrlHashingCalendar', 'admin_spots'),
            updateIsUrlHashingMobileApp: can('updateIsUrlHashingMobileApp', 'admin_spots'),
            updateIsUrlHashingDirectLink: can('updateIsUrlHashingDirectLink', 'admin_spots'),
            updateIsUrlHashingExtension: can('updateIsUrlHashingExtension', 'admin_spots'),
            updatePostBackUrlPush: can('updatePostBackUrlPush', 'admin_spots'),
            updateBackButtonLinkPush: can('updateBackButtonLinkPush', 'admin_spots'),
            updateUnsupportedLinkPush: can('updateUnsupportedLinkPush', 'admin_spots'),
            updateAlreadySubscribedLinkPush: can('updateAlreadySubscribedLinkPush', 'admin_spots'),
            updateDeniedLinkPush: can('updateDeniedLinkPush', 'admin_spots'),
            updateAllowedLinkPush: can('updateAllowedLinkPush', 'admin_spots'),
            updateSetUpTrafficBackPush: can('updateSetUpTrafficBackPush', 'admin_spots'),
            updateLandingTypePush: can('updateLandingTypePush', 'admin_spots'),
            updateCloseRadiusInpagePush: can('updateCloseRadiusInpagePush', 'admin_spots'),
            updateCloseButtonResizeByInpagePush: can(
              'updateCloseButtonResizeByInpagePush',
              'admin_spots'
            ),
            updateCustomSettingsInpagePush: can('updateCustomSettingsInpagePush', 'admin_spots'),
            updateCustomSettingsBanner: can('updateCustomSettingsBanner', 'admin_spots'),
            updateCustomSettingsPopunder: can('updateCustomSettingsPopunder', 'admin_spots'),
            updateCustomSettingsInStreamAd: can('updateCustomSettingsInStreamAd', 'admin_spots'),
            updateSubInterstitialSettingsPush: can(
              'updateSubInterstitialSettingsPush',
              'admin_spots'
            ),
            updateDeepLink: can('updateDeepLink', 'spots'),
          },
        },
        loading: false,
      });
      (this as any)?._vm?.$metrika?.setUserID(response.data.email);
    } catch (errors) {
      commit(SET_USER, {
        ...state.user,
        loading: false,
        errors: (errors as AxiosError)?.response?.data?.data?.errors ?? {},
      });
    }
  },

  async [FETCH_PROFILE]({ state, commit }) {
    try {
      commit(SET_PROFILE, { ...state.profile, loading: true, errors: {} });
      const response = await UserService.getProfile();
      commit(SET_PROFILE, {
        ...state.profile,
        data: { ...state.profile.data, ...response.data },
        loading: false,
      });
    } catch (errors) {
      commit(SET_PROFILE, {
        ...state.profile,
        loading: false,
        errors: (errors as AxiosError)?.response?.data?.data?.errors ?? {},
      });
    }
  },

  async [UPDATE_PROFILE]({ commit }, payload: IProfile) {
    try {
      commit(SET_PROFILE, { ...state.profile, loading: true, errors: {} });
      const response = await UserService.updateProfile(payload);
      commit(SET_PROFILE, {
        ...state.profile,
        data: { ...state.profile.data, ...response.data },
        loading: false,
      });
    } catch (errors) {
      commit(SET_PROFILE, {
        ...state.profile,
        loading: false,
        errors: (errors as AxiosError)?.response?.data?.data?.errors ?? {},
      });
      throw errors;
    }
  },

  async [REVOKE_GOOGLE_PROPERTY]({ commit }) {
    const google_analytics_refresh_token_status = await UserService.revokeGoogleProperty();
    commit(SET_USER, {
      ...state.user,
      data: { ...state.user.data, google_analytics_refresh_token_status },
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
