import router from '@/router';
import ApiService from '@/services/ApiService';
import NotificationService from '@/services/NotificationService';
import { LoginService } from '@/services/StorageService';
import store from '@/store';
import { can } from '@/store/helpers';
import {
  FETCH_AD_FORMATS,
  FETCH_MAINTENANCE,
  FETCH_META_DATA,
  FETCH_NOTIFICATIONS,
  FETCH_PAYMENT_METHODS,
  FETCH_ROLES,
  FETCH_TECHNICAL_ALERTS,
  GET_NOTIFICATIONS_COUNT,
  INIT_USER,
} from '@/store/types/actions';
import initDashly from '@/widgets/dashly';

const initialize = async (persistUserRoles: boolean, prevRoute: string | null) => {
  await store.dispatch(INIT_USER);
  await store.dispatch(FETCH_MAINTENANCE);
  await store.dispatch(FETCH_TECHNICAL_ALERTS);
  if (!can('view', 'admin_panels') && process.env.VUE_APP_ORG_NAME === 'Clickadilla') {
    initDashly();
    window.dashly.connect(process.env.VUE_APP_DASHLY_API_KEY);
    window.dashly.auth(store.getters.user.data.uuid, store.getters.user.data.dashly_hash);
  }
  await store.dispatch(FETCH_AD_FORMATS);
  await store.dispatch(FETCH_PAYMENT_METHODS);
  if (can('viewAny', 'roles')) await store.dispatch(FETCH_ROLES);
  if (prevRoute) {
    await router.replace(store.getters.user.data.has_tags ? prevRoute : '/ad-codes');
  }
  if (persistUserRoles) {
    LoginService.setLogin();
  }
  if (can('viewAny', 'metas')) await store.dispatch(FETCH_META_DATA);
  if (can('viewAny', 'notification_users')) {
    ApiService.initSocket({
      broadcaster: 'socket.io',
      host: process.env.VUE_APP_ECHO_BASEURL,
      auth: {
        headers: {
          Authorization: `Bearer ${store.getters.accessToken.data.token}`,
        },
      },
    });
    store.dispatch(FETCH_NOTIFICATIONS, {
      type: null,
      status: 'unmarked',
      page: 1,
      limit: 0,
      section: 'menu',
    });
    store.dispatch(GET_NOTIFICATIONS_COUNT);
    NotificationService.subscribeToNotifications();
    NotificationService.subscribeToBroadcastEvents();
  }
};

export { initialize };
