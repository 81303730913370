


















import { Component, Prop, Vue } from 'vue-property-decorator';
import Teleport from 'vue2-teleport';

type Coords = { x: number; y: number };

@Component({
  name: 'Tooltip',
  components: { Teleport },
})
export default class Tooltip extends Vue {
  @Prop({ default: '' }) text!: string;
  @Prop({ default: 'none' }) maxWidth!: string;
  @Prop({ default: false, type: Boolean }) disabled!: boolean;
  @Prop({ default: false, type: Boolean }) top!: boolean;
  @Prop({ default: false, type: Boolean }) topCenter!: boolean;
  @Prop({ default: false, type: Boolean }) right!: boolean;
  @Prop({ default: false, type: Boolean }) left!: boolean;
  @Prop({ default: false, type: Boolean }) leftLg!: boolean;
  @Prop({ default: false, type: Boolean }) leftXl!: boolean;
  @Prop({ default: true, type: Boolean }) relative!: boolean;
  @Prop({ type: String }) position: undefined | 'top' | 'right' | 'bottom' | 'left';

  value = false;
  coords: Coords = { x: 0, y: 0 };

  changeValue(value: boolean) {
    if (this.disabled) return null;
    this.value = value;
  }

  get tempPosition(): string {
    if (this.position) return this.position;
    if (this.top) return 'top';
    if (this.right) return 'right';
    if (this.left) return 'left';
    return 'bottom';
  }

  get classes() {
    const result = this.isClickadilla ? this.TooltipClassesClickadilla : this.TooltipClassesOld;
    return { ...result, ...this.TooltipClassesDefault };
  }

  get isClickadilla() {
    return this.env.VUE_APP_ORG_NAME === 'Clickadilla';
  }

  get TooltipClassesDefault() {
    return {
      'tw-opacity-0 tw-pointer-events-none': !this.value,
      'tw-rounded-md': ['Clickadilla', 'Onclicka'].includes(this.env.VUE_APP_ORG_NAME),
      'tw-transition-opacity tw-duration-300 tw-flex tw-w-max tw-font-normal': true,
      'dark:tw-text-mybid-dark': this.env.VUE_APP_ORG_NAME === 'Onclicka',
      'tw-text-white': this.env.VUE_APP_ORG_NAME === 'MyBid',
    };
  }

  get TooltipClassesOld() {
    const { topCenter, top, right, leftXl, left, leftLg } = this;
    return {
      'tw-bottom-full tw-transform tw--translate-x-1/2': topCenter,
      'tw-bottom-12 tw-left-0': top,
      'tw-bottom-0 tw-left-6': right,
      'tw-bottom-0 tw-right-6': left,
      'tw--bottom-12 tw--left-44': leftLg,
      'tw--bottom-12 tw--left-52': leftXl,
      'tw-px-2 tw-py-1 tw-bg-mybid-secondary ': true,
    };
  }

  get TooltipClassesClickadilla() {
    const { tempPosition } = this;
    return {
      [`position-${tempPosition}`]: tempPosition,
      'tw-max-w-250 tw-whitespace-normal tw-bg-gray tw-p-2 dark:tw-bg-black text-color tw-text-center': true,
    };
  }

  changeCoords(coords: Coords) {
    if (this.disabled) return null;

    this.coords = coords;
  }

  get env() {
    return process.env;
  }
}
