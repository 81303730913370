export const INIT_USER = 'INIT_USER';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const FETCH_PROFILE = 'FETCH_PROFILE';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REFRESH = 'REFRESH';
export const REGISTER = 'REGISTER';
export const REGISTER_BY_INVITATION = 'REGISTER_BY_INVITATION';
export const FETCH_PAYMENT_METHODS = 'FETCH_PAYMENT_METHODS';
export const FETCH_AD_FORMATS = 'FETCH_AD_FORMATS';
export const REVOKE_GOOGLE_PROPERTY = 'REVOKE_GOOGLE_PROPERTY';
export const FETCH_ACCRUAL_RULE_TYPES = 'FETCH_ACCRUAL_RULE_TYPES';
export const FETCH_META_DATA = 'FETCH_META_DATA';
export const FETCH_MAINTENANCE = 'FETCH_MAINTENANCE';
export const FETCH_TECHNICAL_ALERTS = 'FETCH_TECHNICAL_ALERTS';
export const FETCH_ROLES = 'FETCH_ROLES';
export const FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS';
export const GET_NOTIFICATIONS_COUNT = 'GET_NOTIFICATIONS_COUNT';
export const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS';
